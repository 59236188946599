import { Card, CardContent, Grid } from '@material-ui/core'
import {
  components,
  constants,
  useTranslation,
  useServices
} from 'cng-web-lib'

import ArrivalmessageApiUrls from 'src/apiUrls/ArrivalmessageApiUrls'
import React, { useRef, useState,useEffect } from 'react'
import ArrivalmessageKeys from 'src/constants/locale/key/Arrivalmessage'
import Namespace from 'src/constants/locale/Namespace'
import moment from 'moment'
import FileCopy from '@material-ui/icons/FileCopy'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router-dom'
import { StatusStyle} from 'src/common/NACommon'
import ConfirmationDialog from 'src/views/common/ConfirmationDialog'
import SendIcon from '@material-ui/icons/Send'
import {FileForUserGetPartyId,FileForUserGetUserDetails,GetFilterByUserIdCriteria} from 'src/common/FileForUserCommon'


const {
  card: {  CngSimpleCardHeader },
  table: {
     CngCrudTable,
     DateRangeFilter,
    useDefaultNotification,
    useFetchCodeMaintenanceLookup
  }
} = components

const {
  locale: {
    key: { UiComponentKeys }
  },
  filter: {EQUAL}
} = constants

function TablePage(props) {
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const {createRecord} = useServices()
  const history = useHistory()
  const {
    location: { pathname },
    showNotification
  } = props
  const [openDialog, setOpenDialog] = useState(false)
  let module=""; 
  let filter = [];
  if(props.location.pathname.toString().indexOf("air")!==-1)
  {
    module="AIR";
  }
  else if(props.location.pathname.toString().indexOf("highway")!==-1)
  {
    module="HIGHWAY";
  }
  else
  {
    filter = GetFilterByUserIdCriteria("ACIOCN")
    module="OCEAN";
  }

  const notification = useDefaultNotification(showNotification)
  const { translate } = useTranslation([
    Namespace.UI_COMPONENT,
    Namespace.ARRIVALMESSAGE
  ])
  const translatedTextsObject = makeTranslatedTextsObject()
  const cngTableRef = useRef()
  const row = useRef()

  const [partyIdState, setPartyIdState] = useState(0);

  useEffect(() => { 
    setPartyIdState(FileForUserGetPartyId());
   }, []);

  function makeTranslatedTextsObject() {
    let arrivalmessage = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.TITLE
    )
    let tableTitle = translate(
      Namespace.UI_COMPONENT,
      UiComponentKeys.Table.TITLE,
      {
        nameTitleised: arrivalmessage
      }
    )
    let carriercode = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CARRIERCODE
    )
    let conveyancereferencenumber = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CONVEYANCEREFERENCENUMBER
    )
    let arrivaldatetime = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ARRIVALDATETIME
    )
    let portofarrival = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.PORTOFARRIVAL
    )
    let scheduledSubmissionFlag = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SCHEDULED_SUBMISSION_FLAG
    )
    let scheduleDateAndTime = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SCHEDULE_DATE_AND_TIME
    )
    let autoSubmitTimeZone = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.AUTO_SUBMIT_TIME_ZONE
    )
    let creationdatetime = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CREATIONDATETIME
    )
    let submissionDateTime = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SUBMISSION_DATE_TIME
    )
    let status = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.STATUS
    )
    let editMultipleRecords = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.EDIT_MULTIPLE_RECORDS
    )
    let editSentRecords = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.EDIT_SENT_RECORDS
    )
    let editButton = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.EDIT_BUTTON
    )
    let viewButton = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.VIEW_BUTTON
    )
    let cloneButton = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CLONE_BUTTON
    )
    let viewMultipleRecords = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.VIEW_MULTIPLE_RECORDS
    )
    let cloneMultipleRecords = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CLONE_MULTIPLE_RECORDS
    )
    let arrivalMessageTable = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.A6A_TABLE
    )
    let cancelButton = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CANCEL_BUTTON
    )
    let submitButton = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SUBMIT_BUTTON
    )
    let deleteButton = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.DELETE_BUTTON
    )
    let deleteMultipleRecords = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.DELETE_MULTIPLE_RECORDS 
    )
    let submitSentRecord = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SUBMIT_SENT_RECORD
    )
    let submitSuccessMsg = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SUBMIT_SUCCESS_MSG
    )
    let submitErrorMsg = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SUBMIT_ERROR_MSG
    )
    let submitRecordDialogContent = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SUBMIT_RECORD_DIALOG_CONTENT
    )
    let submitRecord = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SUBMIT_RECORD
    )

    let confirmedRecord = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CONFIRMED_RECORD
    )
    let functionalAckRecord = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.FUNCTIONAL_ACK_RECORD
    )
    let inbondDestincationOfficeCode = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.INBONDDESTINATIONOFFICECODE
    )
    let warehouseCode = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.WAREHOUSECODE
    )
    let messageFunction = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.MESSAGEFUNCTION
    )
    let csaArrival = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CSAARRIVAL
    )
    let businessNumber = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.BUSINESSNUMBER
    )
    let ccn = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CCN
    )
    return {
      arrivalmessage,
      tableTitle,
      carriercode,
      conveyancereferencenumber,
      arrivaldatetime,
      portofarrival,
      scheduledSubmissionFlag,
      scheduleDateAndTime,
      autoSubmitTimeZone,
      creationdatetime,
      status,
      arrivalMessageTable,
      deleteButton,
      deleteMultipleRecords,
      submitButton,
      cancelButton,
      editMultipleRecords,
      editSentRecords,
      editButton,
      viewButton,
      cloneButton,
      viewMultipleRecords,
      cloneMultipleRecords,
      submitSentRecord,
      submitSuccessMsg,
      submitErrorMsg,
      submitRecordDialogContent,
      submitRecord,
      confirmedRecord,
      functionalAckRecord,
      inbondDestincationOfficeCode,
      warehouseCode,
      messageFunction,
      csaArrival,
      businessNumber,
      ccn,
      submissionDateTime
    }
  }

  const highwayArrivalcolumns = [
    {
      field: "conveyancereferencenumber",
      title: "Cargo Control Number",
    },
    {
      field: "inbondDestincationOfficeCode",
      title: translatedTextsObject.inbondDestincationOfficeCode,
    },
    {
      field: "createdDate",
      title: translatedTextsObject.creationdatetime,
      type: "date",
      filterComponent: DateRangeFilter,
      render: (rowData) => {
        return moment(rowData.createdDate).format('DD/MM/YYYY HH:mm:ss')
      },
      type: "date",
      filterComponent: DateRangeFilter,
      defaultSort: 'desc'
    },
    {
      field: "status",
      title: translatedTextsObject.status,
      filterPlaceholder:' ',
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          undefined,
          [{ field: 'codeType', operator: EQUAL, value: 'AM_STATUS' }],
          undefined,
          'code'
        )
      },
      cellStyle: (e, rowData) => {
        return StatusStyle(rowData.status);
      }
    },
  ]
  const columns = [
    
    {
      field: "carriercode",
      title: translatedTextsObject.carriercode,
    },
    {
      field: "conveyancereferencenumber",
      title: translatedTextsObject.conveyancereferencenumber,
    },
    {
      field: "portofarrival",
      title: translatedTextsObject.portofarrival,
    },
    {
      field: "arrivaldatetime",
      title: translatedTextsObject.arrivaldatetime,
      type: "date",
      filterComponent: DateRangeFilter,
      render: (rowData) => {
        return moment(rowData.arrivaldatetime).format('D MMM YYYY, HH:mm')
      },
      type: "date",
      filterComponent: DateRangeFilter,
      defaultSort: 'desc'
    },
    {
      field: "createdDate",
      title: translatedTextsObject.creationdatetime,
      type: "date",
      filterComponent: DateRangeFilter,
      render: (rowData) => {
        return moment(rowData.createdDate).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
      },
      type: "date",
      filterComponent: DateRangeFilter,
      defaultSort: 'desc'
    },
    {
      field: "submissionDateTime",
      title: translatedTextsObject.submissionDateTime,
      type: "date",
      filterComponent: DateRangeFilter,
      render: (rowData) => {
        return moment(rowData.submissionDateTime).tz("Canada/Eastern").format('D MMM YYYY, HH:mm')
      },
      type: "date",
      filterComponent: DateRangeFilter,
      defaultSort: 'desc'
    },
    {
      field: "status",
      title: translatedTextsObject.status,
      filterPlaceholder:' ',
      customLookup: () => {
        return fetchCodeMaintenanceLookup(
          constants.CodeMaintenanceType.CODE_MASTER,
          undefined,
          [{ field: 'codeType', operator: EQUAL, value: 'AM_STATUS' }],
          undefined,
          'code'
        )
      },
      cellStyle: (e, rowData) => {
        return StatusStyle(rowData.status);
      }
    },
  ]
  
  return (
    <Card>
      <CngSimpleCardHeader title={translatedTextsObject.tableTitle} />
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
          {(module=== "AIR" || module === "OCEAN") &&(
            <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={columns}
              del={{ url: ArrivalmessageApiUrls.DELETE }}
              delAll={{ url: ArrivalmessageApiUrls.DELETE_ALL }}
              editRoute={`${pathname}/edit`}
              // exportData={{ url: ArrivalmessageApiUrls.EXPORT }}
              fetch={{ url: ArrivalmessageApiUrls.GET }}
              fetchFilters={
                [
                  {
                    field:'module', operator:'equal', value: module
                  },
                  {
                    field:'partyId', operator:'equal', value: partyIdState
                  },
                  ...filter
                ]
              }
              idAccessor="id"
              notification={notification}
              isSelection={true}
              isSelectionDelete={true}
             // tableConfigurationCode="OCNArrivalMessage"
              viewRoute={`${pathname}/view`}
              editButtonProps={{
                disableIf: (rowData) => {
                  return (rowData.status === '1048' || rowData.status === '1' || rowData.status==='1000' ||  rowData.status==='17')
                },
                onClick: (e, rowData) => { 
                  const recordId = rowData.id
                  history.push(`${pathname}/edit/`+ recordId)
                }, 
                customTooltip: (rowData) => {
                  if(rowData.status === '1048' || rowData.status === '1'){
                      return translatedTextsObject.editSentRecords
                   } else if (rowData.status === '1000')
                   return translatedTextsObject.confirmedRecord
                   else if (rowData.status === '17')
                   return translatedTextsObject.functionalAckRecord
                  else {
                      return translatedTextsObject.editButton
                  }
                }
              }}
              deleteButtonProps={{
                disableIf: (rowData) => {
                  return (rowData.status !== '1005')
                },
                customTooltip: (rowData) => {
                  if(rowData.status !== '1005'){
                    return translatedTextsObject.deleteMultipleRecords
                  } else {
                    return translatedTextsObject.deleteButton
                  }
                }
              }}
              deleteAllButtonProps={{
                disableIf: (rowData) => {
                  return DisableDeleteButton(rowData)
                },
                customTooltip: (rowData) => {
                  return (DisableDeleteButton(rowData)) ? translatedTextsObject.deleteMultipleRecords : translatedTextsObject.deleteButton
                }
              }}
                options={{
                  selection: true,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100, 500, 1000]
                }}
              cngTableRef={cngTableRef}
              actions={[
                {
                  name:'Clone',
                  icon: (props) =><Box> <FileCopy {...props} fontSize="small"/></Box>,
                  onClick: (e, rowData) => 
                  createRecord.execute(
                    ArrivalmessageApiUrls.CLONE,
                        {
                      ...rowData[0],
                        },
                        (data) => {
                          
                          history.push(`${pathname}/add`,data)
                          
                        },
                        (error) =>{
                          console.log(error)
                        }
                  ),
                  customTooltip: (rowData) => (rowData.length > 1) ? translatedTextsObject.cloneMultipleRecords : translatedTextsObject.cloneButton,
                  disableIf:(rowData) => {
                    return (rowData.length > 1)
                  }
                },{
                  name: 'Submit',
                  icon: (props) =><Box> <SendIcon {...props} fontSize="small" /></Box>,
                  onClick: (e, rowData) => {

                    let fileForUserDetails = FileForUserGetUserDetails();
                    if(fileForUserDetails!=null && fileForUserDetails!=undefined){
                    
                      for(let i=0; i< rowData.length; i++){
                        rowData[i].fileForUserId = fileForUserDetails.fileForUserId;
                        rowData[i].fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
                        rowData[i].fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
                      }
                    }	
                    setOpenDialog(true)
                    row.current = rowData
                  },
                  disableIf: (rowData) => {
                    return DisableSubmitButton(rowData)
                  },
                  customTooltip: (rowData) => {
                    return (DisableSubmitButton(rowData)) ? translatedTextsObject.submitSentRecord : translatedTextsObject.submitButton
                  }
                }
              ]}
            /> 
          )}
          {(module=== "HIGHWAY") &&(
             <CngCrudTable
              {...props}
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              addRoute={`${pathname}/add`}
              columns={highwayArrivalcolumns}
              del={{ url: ArrivalmessageApiUrls.DELETE }}
              delAll={{ url: ArrivalmessageApiUrls.DELETE_ALL }}
              editRoute={`${pathname}/edit`}
              // exportData={{ url: ArrivalmessageApiUrls.EXPORT }}
              fetch={{ url: ArrivalmessageApiUrls.GET }}
              fetchFilters={
                [
                  {
                    field:'module', operator:'equal', value: module
                  },
                  {
                    field:'partyId', operator:'equal', value: partyIdState
                  }
                ]
              }
              idAccessor="id"
              notification={notification}
              isSelection={true}
              isSelectionDelete={true}
             // tableConfigurationCode="OCNArrivalMessage"
              viewRoute={`${pathname}/view`}
              editButtonProps={{
                disableIf: (rowData) => {
                  return (rowData.status === '1048' || rowData.status === '1' || rowData.status==='1000' ||  rowData.status==='17')
                },
                onClick: (e, rowData) => { 
                  const recordId = rowData.id
                  history.push(`${pathname}/edit/`+ recordId)
                }, 
                customTooltip: (rowData) => {
                  if(rowData.status === '1048' || rowData.status === '1'){
                      return translatedTextsObject.editSentRecords
                   } else if (rowData.status === '1000')
                   return translatedTextsObject.confirmedRecord
                   else if (rowData.status === '17')
                   return translatedTextsObject.functionalAckRecord
                  else {
                      return translatedTextsObject.editButton
                  }
                }
              }}
              deleteButtonProps={{
                disableIf: (rowData) => {
                  return (rowData.status !== '1005')
                },
                customTooltip: (rowData) => {
                  if(rowData.status !== '1005'){
                    return translatedTextsObject.deleteMultipleRecords
                  } else {
                    return translatedTextsObject.deleteButton
                  }
                }
              }}
              deleteAllButtonProps={{
                disableIf: (rowData) => {
                  return DisableDeleteButton(rowData)
                },
                customTooltip: (rowData) => {
                  return (DisableDeleteButton(rowData)) ? translatedTextsObject.deleteMultipleRecords : translatedTextsObject.deleteButton
                }
              }}
                options={{
                  selection: true,
                  pageSize: 10,
                  pageSizeOptions: [10, 20, 50, 100, 500, 1000]
                }}
              cngTableRef={cngTableRef}
              actions={[
                {
                  name:'Clone',
                  icon: (props) =><Box> <FileCopy {...props} fontSize="small"/></Box>,
                  onClick: (e, rowData) => 
                  createRecord.execute(
                    ArrivalmessageApiUrls.CLONE,
                        {
                      ...rowData[0],
                        },
                        (data) => {
            
                          history.push(`${pathname}/add`,data)
                        },
                        (error) =>{
                          console.log(error)
                        }
                  ),
                  customTooltip: (rowData) => (rowData.length > 1) ? translatedTextsObject.cloneMultipleRecords : translatedTextsObject.cloneButton,
                  disableIf:(rowData) => {
                    return (rowData.length > 1)
                  }
                },{
                  name: 'Submit',
                  icon: (props) =><Box> <SendIcon {...props} fontSize="small" /></Box>,
                  onClick: (e, rowData) => {

                    let fileForUserDetails = FileForUserGetUserDetails();
                    if(fileForUserDetails!=null && fileForUserDetails!=undefined){
                    
                      for(let i=0; i< rowData.length; i++){
                        rowData[i].fileForUserId = fileForUserDetails.fileForUserId;
                        rowData[i].fileForUserPartyId = fileForUserDetails.fileForUserPartyId;
                        rowData[i].fileForUserLoginId = fileForUserDetails.fileForUserLoginId;
                      }
                    }	
                    
                    setOpenDialog(true)
                    row.current = rowData
                  },
                  disableIf: (rowData) => {
                    return DisableSubmitButton(rowData)
                  },
                  customTooltip: (rowData) => {
                    return (DisableSubmitButton(rowData)) ? translatedTextsObject.submitSentRecord : translatedTextsObject.submitButton
                  }
                }
              ]}
            /> 
          )}
          </Grid>
        </Grid>
      </CardContent>
      <ConfirmationDialog
        url={ArrivalmessageApiUrls.SUBMIT_ALL}
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        onCancel={()=>{setOpenDialog(false)}}
        cngTableRef={cngTableRef}
        cancelButtonName={translatedTextsObject.cancelButton}
        submitButtonName={translatedTextsObject.submitButton}
        rowData={row.current}
        title={translatedTextsObject.submitRecord}
        message={translatedTextsObject.submitRecordDialogContent}
        showNotification={showNotification}
        successMsg={translatedTextsObject.submitSuccessMsg}
        errorMsg={translatedTextsObject.submitErrorMsg}
      />
    </Card>
  )
  function DisableDeleteButton(rowData){
    var isDisable = false
    for(let record in rowData){
      var row = rowData[record]
      if(row.status !== '1005'){
        isDisable = true
        break
      }
    }
    return isDisable
  }
  function DisableSubmitButton(rowData){
    var isDisable = false
    for(let record in rowData){
      var row = rowData[record]
      if(row.status === '1048' || row.status === '1' || row.status === '1000' || row.status ==='17'){
        isDisable = true
        break
      }
    }
    return isDisable
  }
}

export default TablePage
