import React,{ useEffect} from 'react'
import Namespace from 'src/constants/locale/Namespace'
import ArrivalmessageKeys from 'src/constants/locale/key/Arrivalmessage'
import { Grid, Card, CardContent,Typography } from '@material-ui/core'
import { components, DataFlattener, constants } from 'cng-web-lib'
import makeValidationSchema from './MakeValidationSchema'
import { useTranslation } from 'cng-web-lib'
import ArrivalMessageSNDTOsFormProperties from './ArrivalMessageSNDTOsFormProperties'
import {NaCustomsOfficeAutocompleteField} from 'src/components/na'
import { useLocation } from 'react-router-dom'
import moment from 'moment'
import { useFormContext, useWatch } from "react-hook-form";
import {FileForUserGetUserDetails} from 'src/common/FileForUserCommon'

const {
  card: {
     CngSimpleCardHeader,
  },
  form: {
    adapter: { 
      useFormAdapter:{ useField } 
    },
    field: {
      CngTextField,
      CngDateTimeField,
      CngSwitchField,
      CngCodeMasterAutocompleteField,
      CngDateField,
      CngTimeField
    },
    CngAddFormButtonSection,
    CngEditFormButtonSection
  },
  table: {
     CngLocalModeDialogFormTable,
     useFetchCodeMaintenanceLookup
  },
   CngGridItem,
} = components

const {
  filter: {EQUAL}
} = constants

const DEFAULT_INITIAL_VALUES = Object.freeze({
  id: null,
  version: 0,
  carriercode: "",
  conveyancereferencenumber: "",
  arrivaldatetime: "",
  portofarrival: "",
  module: "",
  scheduledSubmissionFlag: false,
  scheduleDateAndTime: "",
  scheduleDate: "",
  scheduleTime: "",
  templateFlag: false,
  templateName: "",
  status: "",
  autoSubmitTimeZone: "",
  scheduleDateAndTimeDisplay:"",
  arrivalMessageSNDTOs: [],
})

const FORMIK_PROPS = {
  initialValues: { ...DEFAULT_INITIAL_VALUES },
  makeValidationSchema: makeValidationSchema
}


function Fields({ disabled, showNotification, shouldHideMap, setAutoSendFlag }) {
  const location = useLocation();
  const fetchCodeMaintenanceLookup = useFetchCodeMaintenanceLookup()
  const doctype = location.pathname.toString().indexOf("air")!=-1 ? "AIR" : "OCEAN"
  const { setValue, getValues, reset } = useFormContext();

  const [TemplateFlag] = useField("templateFlag");
  let isTemplate = TemplateFlag.value

  const [Flag] = useField("scheduledSubmissionFlag");
  let flag = Flag.value

  const [StatusValue] = useField("status");
  let status = StatusValue.value

  const [AutoSubmit] = useField("autoSubmitTimeZone");
  let autoSubmitTimeZone = AutoSubmit.value

  const [ScheduleDateAndTimeValue] = useField("scheduleDateAndTime");
  let scheduleDateAndTime = ScheduleDateAndTimeValue.value

  const [Template] = useField("templateName");
  let templateName = Template.value

  let isEditable=false;
  const editPage=location.pathname.toString().indexOf("edit")!=-1 ? true : false
  const templateStatus =  status === "1002" ? true : false
  if(editPage && isTemplate && templateStatus)
  {
    isEditable = true;
  }

  /*useEffect(() => {
    if(flag===false && autoSubmitTimeZone !=""){
      setValue("autoSubmitTimeZone", "")
    }
    if(flag==false && scheduleDateAndTime !="")
    {
      setValue("scheduleDateAndTime", "")
    }
    if(isTemplate==false && templateName !="")
    {
      setValue("templateName", "")
    }
  }, [autoSubmitTimeZone]);*/
 
  const { translate } = useTranslation(Namespace.ARRIVALMESSAGE)
  const translatedTextsObject = makeTranslatedTextsObject()

  useWatch("fileForUserId");
  useWatch("fileForUserPartyId");
  useWatch("fileForUserLoginId");
  useEffect(() => { 
    let fileForUserDetails = FileForUserGetUserDetails();
      if(fileForUserDetails!=null && fileForUserDetails!=undefined){
        setValue("fileForUserId",fileForUserDetails.fileForUserId)
        setValue("fileForUserPartyId",fileForUserDetails.fileForUserPartyId)
        setValue("fileForUserLoginId",fileForUserDetails.fileForUserLoginId)
      }
   }, []);

   useEffect(() => {
        setAutoSendFlag(flag)
        if(flag === true){
          let arrivalDate = getValues("arrivalDate")
          let scheduleDate = getValues("scheduleDate")

          if(arrivalDate!=null && arrivalDate!=undefined && (scheduleDate ==null || scheduleDate==undefined || scheduleDate=="")){
            setValue("scheduleDate", arrivalDate)
          }

          let arrivalTime = getValues("arrivalTime")
          let scheduleTime = getValues("scheduleTime")

          if(arrivalTime!=null && arrivalTime!=undefined && (scheduleTime ==null || scheduleTime==undefined || scheduleTime=="")){
            setValue("scheduleTime", arrivalTime)
          }

          let autoSubmitTimeZone = getValues("autoSubmitTimeZone")
          if(autoSubmitTimeZone ==null || autoSubmitTimeZone==undefined || autoSubmitTimeZone==""){
            setValue("autoSubmitTimeZone", "NT")
          }
          
        }else{
          setValue("scheduleDate", "")
          setValue("scheduleTime", "")
        }
   }, [flag]);

  function makeTranslatedTextsObject() {
    let arrivalmessage = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.TITLE
    )
    let carriercode = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CARRIERCODE
    )
    let conveyancereferencenumber = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CONVEYANCEREFERENCENUMBER
    )
    let arrivaldatetime = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ARRIVALDATETIME
    )
    let arrivalDate = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ARRIVAL_DATE
    )
    let arrivalTime = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ARRIVAL_TIME
    )
    let portofarrival = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.PORTOFARRIVAL
    )
    let module = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.MODULE
    )
    let scheduledSubmissionFlag = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SCHEDULED_SUBMISSION_FLAG
    )
    let scheduleDateAndTime = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SCHEDULE_DATE_AND_TIME
    )
    let scheduleDate = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SCHEDULE_DATE
    )

    let scheduleTime = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.SCHEDULE_TIME
    )

    let autoSubmitTimeZone = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.AUTO_SUBMIT_TIME_ZONE
    )

    let templateFlag = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.TEMPLATE_FLAG
    )
    let templateName = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.TEMPLATE_NAME
    )

    let creationdatetime = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.CREATIONDATETIME
    )
    let arrivalMessageSNDTOs = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ArrivalMessageSNDTOs.TITLE
    )
    let name = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ArrivalMessageSNDTOs.NAME
    )
    let email = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ArrivalMessageSNDTOs.EMAIL
    )
    let phno = translate(
      Namespace.ARRIVALMESSAGE,
      ArrivalmessageKeys.ArrivalMessageSNDTOs.PHNO
    )
   let  snTyp = translate(
    Namespace.ARRIVALMESSAGE,
    ArrivalmessageKeys.ArrivalMessageSNDTOs.SNTYP
  )
  let  autoSendHelperText = translate(
    Namespace.ARRIVALMESSAGE,
    ArrivalmessageKeys.AUTO_SEND_HELPER_TEXT
  )
    return {
      arrivalmessage,
      carriercode,
      conveyancereferencenumber,
      arrivaldatetime,
      arrivalDate,
      arrivalTime,
      portofarrival,
      scheduledSubmissionFlag,
      scheduleDateAndTime,
      scheduleDate,
      scheduleTime,
      autoSubmitTimeZone,
      creationdatetime,
      templateFlag,
      templateName,
      arrivalMessageSNDTOs,
      name,
      email,
      phno,
      snTyp,
      module,
      autoSendHelperText
    }
  }

	return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.arrivalmessage} />
          <CardContent>
            <Grid container spacing={3}>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.carriercode}>
                <CngTextField
                  name="carriercode"
                  inputProps={{ maxLength: 4 }}        
                  label={translatedTextsObject.carriercode}
                  disabled={disabled}
                  onChange={(e) => {
                       setValue('conveyancereferencenumber',e.target.value.toUpperCase())
                  }
                  }
                  onBlur={(e) => { setValue('carriercode', e.target.value.toUpperCase()) }}
                  required
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.conveyancereferencenumber}>
                <CngTextField
                  name="conveyancereferencenumber"
                  inputProps={{ maxLength: 25 }}        
                  label={translatedTextsObject.conveyancereferencenumber}
                  disabled={disabled}
                  onBlur={(e) => { setValue('conveyancereferencenumber', e.target.value.toUpperCase()) }}
                  required
                />
              </CngGridItem>
              {/*<CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.arrivaldatetime}>
                <CngDateTimeField
                  name="arrivaldatetime"
                  format="YYYY-MM-DD HH:mm"
                  label={translatedTextsObject.arrivaldatetime + " *"}
                  disabled={disabled}
                />
                </CngGridItem>*/}
              <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.arrivalDate}>
                    <CngDateField
                      required
                      name="arrivalDate"
                      label={translatedTextsObject.arrivalDate}
                      format="YYYY-MM-DD"
                      disabled={disabled}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.arrivalTime}>
                    <CngTimeField
                      required
                      name="arrivalTime"
                      label={translatedTextsObject.arrivalTime}
                      format="HH:mm"
                      disabled={disabled}
                      ampm={false}
                    />
                  </CngGridItem>
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.portofarrival}>
                <NaCustomsOfficeAutocompleteField
                  name="portofarrival"
                  inputProps={{ maxLength: 5 }}        
                  label={translatedTextsObject.portofarrival}
                  disabled={disabled}
                  lookupProps={{
                    filters: [{ field: 'indicator', operator: 'equal', value: 'ARM' }]
                  }}
                  required
                />
              </CngGridItem>
              <CngGridItem xs={12} sm={12} shouldHide={shouldHideMap.scheduledSubmissionFlag}>
                <CngSwitchField
                  name="scheduledSubmissionFlag"
                  label={translatedTextsObject.scheduledSubmissionFlag}
                  disabled={disabled} 
                /> 
                <Typography color='textSecondary' variant='caption'>
                  <Typography variant='inherit' color='error'>Notes :</Typography>
                    {translatedTextsObject.autoSendHelperText}
                  </Typography>
              </CngGridItem>
              {/*(flag===true) &&(
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.scheduleDateAndTime}>
                <CngDateTimeField
                  name="scheduleDateAndTime"
                  format="YYYY-MM-DD HH:mm"
                  shouldDisableDate={(scheduleDateAndTime) => {
                    return moment().subtract(1,'days').isAfter(moment(scheduleDateAndTime))
                  }}
                  label={translatedTextsObject.scheduleDateAndTime + " *"}
                  disabled={disabled}
                />
              </CngGridItem>
                )*/
                (flag===true) && 
                <>
                <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.scheduleDate}>
                    <CngDateField
                      required
                      name="scheduleDate"
                      label={translatedTextsObject.scheduleDate}
                      format="YYYY-MM-DD"
                      disabled={disabled}
                      shouldDisableDate={(scheduleDateAndTime) => {
                        return moment().subtract(1,'days').isAfter(moment(scheduleDateAndTime))
                      }}
                    />
                  </CngGridItem>
                  <CngGridItem xs={12} sm={3} shouldHide={shouldHideMap.scheduleTime}>
                    <CngTimeField
                      required
                      name="scheduleTime"
                      label={translatedTextsObject.scheduleTime}
                      format="HH:mm"
                      disabled={disabled}
                      ampm={false}
                    />
                  </CngGridItem>
                </>
                }
              {(flag===true) &&(
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.autoSubmitTimeZone}>            
                <CngCodeMasterAutocompleteField
                key={getValues("autoSubmitTimeZone")}
                name='autoSubmitTimeZone'
                label={translatedTextsObject.autoSubmitTimeZone}
                disabled={disabled}
                codeType='SCHEDULER_TIME_ZONE'
                required
              />
              </CngGridItem>
              )}
             {/* 
              <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.templateFlag}>
                  <CngSwitchField
                    name="templateFlag"
                    label={translatedTextsObject.templateFlag}
                    disabled={disabled || isEditable}
                  />
              </CngGridItem>
                {(isTemplate===true)&&(
                <CngGridItem xs={12} sm={6} shouldHide={shouldHideMap.templateName}>
                    <CngTextField
                      name="templateName"
                      inputProps={{ maxLength: 50 }}
                      label={translatedTextsObject.templateName + " *"}
                      disabled={disabled || isEditable}                
                      onBlur={(e) => { setValue('templateName', e.target.value.toUpperCase()) }}
                    />
                </CngGridItem>
                )}
              */}
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CngSimpleCardHeader title={translatedTextsObject.arrivalMessageSNDTOs} />
          <CardContent>
            <CngLocalModeDialogFormTable
              fieldLevel="toBeChangedByDeveloperIfWantFieldLevelRestriction"
              disabled={disabled}
              showNotification={showNotification}
              propertyName="arrivalMessageSNDTOs"
              tableColumns={[
                {
                  title: translatedTextsObject.name,
                  field: "name",
                },
                {
                  title: translatedTextsObject.email,
                  field: "email",
                },
                {
                  title: translatedTextsObject.phno,
                  field: "phno",
                },
                {
                  title: translatedTextsObject.snTyp,
                  field: "snTyp",
                  filterPlaceholder:' ',
                  customLookup: () => {
                    return fetchCodeMaintenanceLookup(
                    constants.CodeMaintenanceType.CODE_MASTER,
                    undefined,
                    [{field: 'codeType', operator: EQUAL, value: 'ACIOCN_STATUS_NOTIFI_TYPE'}],
                    undefined,
                    'code'
                    )
                  }

                }
              ]}
              addButtonProps={{
                disableIf: (tableData) => {
                  return tableData.length >= 5
                },
                customTooltip: (tableData) => tableData.length >=5 ? 'Only 5 records allowed': 'Add',
              }}
              formProperties={ArrivalMessageSNDTOsFormProperties}
              initialData={DEFAULT_INITIAL_VALUES.arrivalMessageSNDTOs}
              isViewOnly={disabled}
              idGenerated
              options={{
                pageSize:5,
                pageSizeOptions:[5,3],
              }
            }
            renderAddFormButtonSection={() => {
              return(
                <CngAddFormButtonSection primaryButtonLabel="Save" shouldHideResetButton={true}>
                </CngAddFormButtonSection>
              )
            }}
            renderEditFormButtonSection={() => {
              return(
                <CngEditFormButtonSection primaryButtonLabel="Update" shouldHideResetButton={true}>
                </CngEditFormButtonSection>
              )
            }}
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
	)
}

function toClientDataFormat(serverData) {
  let localData = serverData;
  
  localData.arrivalTime = moment(localData.arrivaldatetime).format('HH:mm');
  localData.arrivalDate = moment(localData.arrivaldatetime).format('YYYY-MM-DD');

  localData.scheduleTime = moment(localData.scheduleDateAndTimeDisplay).format('HH:mm');
  localData.scheduleDate = moment(localData.scheduleDateAndTimeDisplay).format('YYYY-MM-DD');

  localData.scheduleDateAndTime = localData.scheduleDateAndTimeDisplay;
 
  return localData;
}

function toServerDataFormat(localData) {
 
  localData.scheduleDateAndTime = localData.scheduleDate + ' ' + localData.scheduleTime

  localData.arrivaldatetime = localData.arrivalDate + ' ' + localData.arrivalTime

  return DataFlattener.unflatten(localData);
}

const FormProperties = Object.freeze({
  formikProps: FORMIK_PROPS,
  Fields: Fields,
  toClientDataFormat: toClientDataFormat,
  toServerDataFormat: toServerDataFormat
})

export default FormProperties
